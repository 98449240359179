import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
    Menu,
    MenuDivider,
    MenuHeader
} from "@szhsin/react-menu";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';


export const ProfessorFilter = () => {
    const [minAverageRatings, setMinAverageRatings] = useState("");
    const [maxAverageRatings, setMaxAverageRatings] = useState("");
    const [minClarityRatings, setMinClarityRatings] = useState("");
    const [maxClarityRatings, setMaxClarityRatings] = useState("");
    const [minDifficultyRatings, setMinDifficultyRatings] = useState("");
    const [maxDifficultyRatings, setMaxDifficultyRatings] = useState("");
    const [minHelpfulnessRatings, setMinHelpfulnessRatings] = useState("");
    const [maxHelpfulnessRatings, setMaxHelpfulnessRatings] = useState("");
    const history = useHistory();
    const params = new URLSearchParams(useLocation().search);

    useEffect(() => {
        const parameters = [
            ["minAverageRatings", setMinAverageRatings],
            ["maxAverageRatings", setMaxAverageRatings],
            ["minClarityRatings", setMinClarityRatings],
            ["maxClarityRatings", setMaxClarityRatings],
            ["minDifficultyRatings", setMinDifficultyRatings],
            ["maxDifficultyRatings", setMaxDifficultyRatings],
            ["minHelpfulnessRatings", setMinHelpfulnessRatings],
            ["maxHelpfulnessRatings", setMaxHelpfulnessRatings]
        ];

        for (const [key, setter] of parameters) {
            const value = params.get(key);
            if (value) {
                setter(value);
            }
        }
    }, []);


    const applyChanges = () => {
        const parameters = [
            ["minAverageRatings", minAverageRatings],
            ["maxAverageRatings", maxAverageRatings],
            ["minClarityRatings", minClarityRatings],
            ["maxClarityRatings", maxClarityRatings],
            ["minDifficultyRatings", minDifficultyRatings],
            ["maxDifficultyRatings", maxDifficultyRatings],
            ["minHelpfulnessRatings", minHelpfulnessRatings],
            ["maxHelpfulnessRatings", maxHelpfulnessRatings]
        ];

        for (const [key, value] of parameters) {
            if (value) {
                params.set(key, value);
            } else {
                params.delete(key);
            }
        }

        history.push({
            pathname: "/search",
            search: params.toString(),
        });
        window.location.reload();
    }

    const clearChanges = () => {
        setMinAverageRatings("");
        setMaxAverageRatings("");
        setMinDifficultyRatings("");
        setMaxDifficultyRatings("");
        setMinClarityRatings("");
        setMaxClarityRatings("");
        setMinHelpfulnessRatings("");
        setMaxHelpfulnessRatings("");
        params.delete("minAverageRatings");
        params.delete("maxAverageRatings");
        params.delete("minClarityRatings");
        params.delete("maxClarityRatings");
        params.delete("minDifficultyRatings");
        params.delete("maxDifficultyRatings");
        params.delete("minHelpfulnessRatings");
        params.delete("maxHelpfulnessRatings");
        history.push({
            pathname: "/search",
            search: params.toString(),
        });
        window.location.reload();
    }

    const professorRatingsRange = (minValue, setMinValue, maxValue, setMaxValue) => {
        return (
            <InputGroup
                style={{
                    marginBottom: "15px",
                }}
            >
                <Form.Control
                    placeholder="Min"
                    htmlSize={4}
                    style={{
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        marginLeft: "10px",
                    }}
                    onChange={(e) => {
                        setMinValue(e.target.value);
                    }}
                    value={minValue}
                />
                <span style={{ marginLeft: "10px", marginRight: "1.25px", marginTop: "5px" }}> - </span>
                <Form.Control
                    placeholder="Max"
                    htmlSize={4}
                    style={{
                        marginRight: "25px",
                        marginLeft: "10px",
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                    }}
                    onChange={(e) => {
                        setMaxValue(e.target.value);
                    }}
                    value={maxValue}
                />
            </InputGroup>
        );
    };


    const buttons = () => {
        return (
            <>
                <Button
                    variant="primary"
                    style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        fontFamily: "Inter vars, sans-serif",
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderTopRightRadius: "25px"
                    }}
                    id="apply-filter"
                    onClick={applyChanges}
                >
                    Apply
                </Button>
                <Button
                    variant="outline-primary"
                    style={{
                        fontFamily: "Inter vars, sans-serif",
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderTopRightRadius: "25px"
                    }}
                    onClick={clearChanges}
                >
                    Clear
                </Button>
            </>
        )
    }


    const renderMenu = () => (
        <>
            <Menu
                menuButton={
                    <Button
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px",
                        }}
                    >
                        Professor Ratings
                    </Button>
                }
                transition
            >
                <MenuHeader>Professor Average Rating</MenuHeader>
                {professorRatingsRange(
                    minAverageRatings,
                    setMinAverageRatings,
                    maxAverageRatings,
                    setMaxAverageRatings
                )}
                <MenuDivider />
                <MenuHeader>Professor Clarity Rating</MenuHeader>
                {professorRatingsRange(
                    minClarityRatings,
                    setMinClarityRatings,
                    maxClarityRatings,
                    setMaxClarityRatings
                )}
                <MenuDivider />
                <MenuHeader>Professor Difficulty Rating</MenuHeader>
                {professorRatingsRange(
                    minDifficultyRatings,
                    setMinDifficultyRatings,
                    maxDifficultyRatings,
                    setMaxDifficultyRatings
                )}
                <MenuDivider />
                <MenuHeader>Professor Helpfulness Rating</MenuHeader>
                {professorRatingsRange(
                    minHelpfulnessRatings,
                    setMinHelpfulnessRatings,
                    maxHelpfulnessRatings,
                    setMaxHelpfulnessRatings
                )}
                {buttons()}
            </Menu>
        </>
    );

    if (
        (window.innerWidth <= 414) ||
        (window.innerWidth <= 769 && window.innerWidth > 414) ||
        (window.innerWidth <= 1367 && window.innerWidth > 769) ||
        (window.innerWidth <= 1921 && window.innerWidth > 1367) ||
        (window.innerWidth > 1921)
    ) {
        return renderMenu();
    }

}