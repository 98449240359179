// filter by price
// sort by price (low to high, high to low)
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
    Menu,
    MenuHeader,
    MenuDivider
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Autocomplete } from '@mui/material';
import axios from 'axios';

export const Pricing = () => {
    const [lowToHighPriceSortVar, setlowToHighPriceSortVar] = useState(false);
    const [highToLowPriceSortVar, sethighToLowPriceSortVar] = useState(false);
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [residency, setResidency] = useState("");
    const [zips, setZips] = useState([]);
    const history = useHistory();
    const params = new URLSearchParams(useLocation().search);

    useEffect(() => {
        if (params.get("minPrice")) {
            setMinPrice(params.get("minPrice").toString());
        } 
        if (params.get("maxPrice")) {
            setMaxPrice(params.get("maxPrice").toString());
        }
        if (params.get("sort") && params.get("sort") === "asc") {
            setlowToHighPriceSortVar(true);
        }
        if (params.get("sort") && params.get("sort") === "desc") {
            sethighToLowPriceSortVar(true);
        }
        if (params.get("residency")) {
            setResidency(params.get("residency"));
        }

        axios.get("https://hza6lfrpsz4sm7hmyfk2peouh40ckxqg.lambda-url.us-east-2.on.aws/")
            .then((response) => {
                setZips(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const managePriceSort = (e) => {
        if (e.target.value === "Low to High") {
            if (lowToHighPriceSortVar) {
                setlowToHighPriceSortVar(false);
            } else {
                setlowToHighPriceSortVar(true);
                sethighToLowPriceSortVar(false);
            }
        } else if (e.target.value === "High to Low") {
            if (highToLowPriceSortVar) {
                sethighToLowPriceSortVar(false);
            } else {
                setlowToHighPriceSortVar(false);
                sethighToLowPriceSortVar(true);
            }
        } else {
            setlowToHighPriceSortVar(false);
            sethighToLowPriceSortVar(false);
        }
    }

    const applyChanges = () => {
        if (lowToHighPriceSortVar) {
            params.set("sort", "asc");
        } else if (highToLowPriceSortVar) {
            params.set("sort", "desc");
        }

        if (!lowToHighPriceSortVar && !highToLowPriceSortVar) {
            params.delete("sort");
        }

        if (minPrice) {
            if (minPrice === "") {
                params.delete("minPrice");
            } else {
                params.set("minPrice", minPrice);
            }
        }  else {
            params.delete("minPrice");
        }

        if (maxPrice) {
            if (maxPrice === "") {
                params.delete("maxPrice");
            } else {
                params.set("maxPrice", maxPrice);
            }
        } else {
            params.delete("maxPrice");
        }

        if (residency) {
            if (residency === "") {
                params.delete("residency");
            } else {
                if (residency[0] === '"' && residency[residency.length - 1] === '"') {
                    params.set("residency", residency);
                } else {
                    params.set("residency", '"' + residency + '"');
                }
            }
        } else {
            params.delete("residency");
        }

        history.push({
            pathname: "/search",
            search: params.toString(),
        });
        window.location.reload();
    }

    const clearChanges = () => {
        setlowToHighPriceSortVar(false);
        sethighToLowPriceSortVar(false);
        setMinPrice("");
        setMaxPrice("");
        setResidency("");
        params.delete("residency");
        params.delete("sort");
        params.delete("minPrice");
        params.delete("maxPrice");
        history.push({
            pathname: "/search",
            search: params.toString(),
        });
        window.location.reload();
    }

    const enterResidency = () => {
        let residencies = zips.map((zip) => {
            return zip.city + ", " + zip.state + " " + zip.zip;
        });

        let options = () => {
            if (residency === "") {
                return residencies.slice(0, 10);
            } else {
                return residencies.filter((option) => option.toLowerCase().includes(residency.toLowerCase())).slice(0, 20);
            }
        }

        return (
            <>
                <MenuHeader>Your Residency</MenuHeader>
                <Autocomplete
                    sx={{
                        width: 200
                    }}
                    id="custom-input-demo"
                    options={options()}
                    onInputChange={(event, value) => {
                        setResidency(value);
                    }}
                    renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                            <Form.Control
                                placeholder='By city, state, or zip'
                                {...params.inputProps}
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "10px",
                                }}
                            />
                        </div>
                    )}
                />
            </>
        );
    }

    const priceRange = () => {
        return (
            <>
                <MenuHeader>Price Range</MenuHeader>
                <InputGroup
                    style={{
                        marginBottom: "15px",
                    }}
                >
                    <InputGroup.Text id="basic-addon1"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                        }}>$</InputGroup.Text>
                    <Form.Control
                        placeholder="Min"
                        htmlSize={4}
                        style={{
                            // round the corners
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                        }}
                        onChange={(e) => {
                            setMinPrice(e.target.value)
                        }}
                        value={minPrice}
                    />
                    <span style={{ marginLeft: "10px", marginRight: "1.25px", marginTop: "5px" }}> - </span>
                    <InputGroup.Text id="basic-addon1"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                        }}>$</InputGroup.Text>
                    <Form.Control
                        placeholder="Max"
                        htmlSize={4}
                        style={{
                            // round the corners
                            marginRight: "10px",
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                        }}
                        onChange={(e) => {
                            setMaxPrice(e.target.value)
                        }}
                        value={maxPrice}
                    />
                </InputGroup>
            </>
        )
    }

    const priceSort = () => {
        return (
            <>
                <MenuHeader>Sort By Price</MenuHeader>
                <div
                    style={{
                        marginLeft: "12.5px",
                        marginBottom: "15px",
                    }}
                >
                    <input
                        value="Low to High"
                        type="checkbox"
                        onChange={managePriceSort}
                        checked={lowToHighPriceSortVar}
                    />
                    <label
                        style={{
                            paddingLeft: "12.5px",
                            paddingRight: "12.5px",
                        }}
                    >
                        Low to High
                    </label>
                </div>
                {/* <div
                    style={{
                        marginLeft: "12.5px",
                        marginBottom: "10px",
                    }}
                >
                    <input
                        value="High to Low"
                        type="checkbox"
                        onChange={managePriceSort}
                        checked={highToLowPriceSortVar}
                    />
                    <label
                        style={{
                            paddingLeft: "12.5px",
                            paddingRight: "12.5px",
                        }}
                    >
                        High to Low
                    </label>
                </div> */}
            </>
        )
    }

    const buttons = () => {
        return (
            <>
                <Button
                    variant="primary"
                    style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        fontFamily: "Inter vars, sans-serif",
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderTopRightRadius: "25px"
                    }}
                    id="apply-filter"
                    onClick={applyChanges}
                >
                    Apply
                </Button>
                <Button
                    variant="outline-primary"
                    style={{
                        fontFamily: "Inter vars, sans-serif",
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderTopRightRadius: "25px"
                    }}
                    onClick={clearChanges}
                >
                    Clear
                </Button>
            </>
        )
    }

    if (window.innerWidth <= 414) {
        return (
            <>
                <Menu
                    menuButton={<Button                        
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Pricing</Button>}
                    transition
                >
                    {enterResidency()}
                    <MenuDivider />
                    {priceRange()}
                    <MenuDivider />
                    {priceSort()}
                    {buttons()}
                </Menu>
            </>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <>
                <Menu
                    menuButton={<Button                        
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Pricing</Button>}
                >
                    {enterResidency()}
                    <MenuDivider />
                    {priceRange()}
                    <MenuDivider />
                    {priceSort()}
                    {buttons()}
                </Menu>
            </>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <>
                <Menu
                    menuButton={<Button                        
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Pricing</Button>}
                >
                    {enterResidency()}
                    <MenuDivider />
                    {priceRange()}
                    <MenuDivider />
                    {priceSort()}
                    {buttons()}
                </Menu>
            </>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <>
                <Menu
                    menuButton={<Button                        
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Pricing</Button>}
                >
                    {enterResidency()}
                    <MenuDivider />
                    {priceRange()}
                    <MenuDivider />
                    {priceSort()}
                    {buttons()}
                </Menu>
            </>
        );
    } else {
        return (
            <>
                <Menu
                    menuButton={<Button                        
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Pricing</Button>}
                >
                    {enterResidency()}
                    <MenuDivider />
                    {priceRange()}
                    <MenuDivider />
                    {priceSort()}
                    {buttons()}
                </Menu>
            </>
        );
    }
}